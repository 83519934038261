<template>
  <div class="slide">
    <transition name="slide" mode="out-in">
      <slot></slot>
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-leave-active {
  position: absolute;
}

.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
