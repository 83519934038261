<template>
  <MainLayout
    :content_title="content_title"
    :toBlur="toBlur || (notifPopups && notifPopups.toBlur)"
  >
    <template v-slot:default v-if="eventsProgress.cguDone">
      <div class="container">
        <div class="row justify-content-center gx-5">
          <!-- First column -->
          <div class="col-10 col-md-5">
            <div class="row">
              <!-- Carousel component -->
              <Carousel
                :navigation="true"
                :pagination="true"
                :showSlides1="statusState !== 'fait'"
                :slides="carouselSlides1"
                :slides2="carouselSlides2"
              />
            </div>
            <div class="row">
              <BlckNotifications
                v-if="notifPopups"
                @showSignDocuments="notifPopups.onShowSignDocuments"
                @showUpdatedDocumentDocuments="
                  notifPopups.onShowUpdatedDocumentDocuments
                "
                :showDocumentsSigning="true"
              />
            </div>
          </div>

          <!-- Second column -->
          <div class="col-10 col-md-5">
            <div class="row">
              <BlckProgress />

              <!-- Third content component -->
              <BlckConstituteFolder />

              <!-- Fourth content component -->
              <BlckSupport />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modals>
      <CGU @closedPopup="onClosedPopup" v-if="displayPopup" :idTerm="idTerm" />
      <Password
        @closedPopup="onClosedPasswordPopup"
        v-if="displayPasswordPopup"
      />
      <NotificationsPopups ref="notifPopups" v-if="eventsProgress.cguDone" />
    </template>
  </MainLayout>
</template>

<script>
import { ref } from "vue";
import { computed } from "vue";
import { mapActions, useStore } from "vuex";
import emitter from "../events/emitter.js";
import events from "../constants/events";
import BlckProgress from "../components/Accueil/BlckProgress.vue";
import BlckNotifications from "../components/Common/BlckNotifications.vue";
import BlckConstituteFolder from "../components/Accueil/BlckConstituteFolder.vue";
import BlckSupport from "../components/Accueil/BlckSupport.vue";
import CGU from "../components/Popups/CGU.vue";
import Password from "../components/Popups/Password.vue";
import MainLayout from "../layouts/main.layout";
import NotificationsPopups from "../components/Popups/NotificationsPopups.vue";
import getCurrentCGURequest from "../services/graphql/requests/getCurrentCGURequest";
import getMainDossierAndTimelineStatusRequest from "../services/graphql/requests/getMainDossierAndTimelineStatusRequest";
import Carousel from "../components/Accueil/Carousel/Carousel.vue";

export default {
  setup() {
    // store
    const store = useStore();

    const getDidReadCGU = computed(() => store.getters["getDidReadCGU"]);

    // refs
    const notifPopups = ref();
    const statusState = ref(null);

    const carouselSlides1 = [
      require("../assets/images/Carrousels STARTO_rdv_slide_1.png"),
      require("../assets/images/Carrousels STARTO_rdv_slide_2.png"),
      require("../assets/images/Carrousels STARTO_rdv_slide_3.png"),
      require("../assets/images/Carrousels STARTO_rdv_slide_4.png"),
      require("../assets/images/Carrousels STARTO_rdv_slide_5.png"),
    ];

    const carouselSlides2 = [
      require("../assets/images/Carrousels STARTO – après rdv_slide_1.png"),
      require("../assets/images/Carrousels STARTO – après rdv_slide_2.png"),
      require("../assets/images/Carrousels STARTO – après rdv_slide_3.png"),
      require("../assets/images/Carrousels STARTO – après rdv_slide_4.png"),
      require("../assets/images/Carrousels STARTO – après rdv_slide_5.png"),
    ];

    return {
      getDidReadCGU,
      notifPopups,
      carouselSlides1,
      carouselSlides2,
      statusState,
    };
  },
  page: {
    title: "Home",
    meta: [{ name: "Home page" }],
  },
  components: {
    MainLayout,
    BlckProgress,
    BlckNotifications,
    BlckConstituteFolder,
    BlckSupport,
    CGU,
    Password,
    NotificationsPopups,
    Carousel,
  },
  data() {
    return {
      content_title: "MON TABLEAU DE BORD",
      toBlur: false,
      displayPopup: false,
      displayPasswordPopup: false,
      idTerm: 0,
      eventsProgress: {
        constituteFolderDone: false,
        notificationsDone: false,
        progressDone: false,
        cguDone: false,
      },
    };
  },
  methods: {
    ...mapActions(["setDidReadCGU"]),
    ...mapActions("cgu", ["getCurrentCgu"]),
    ...mapActions("client", ["getMainClientLastTerm", "setClientLastLogin"]),
    onClosedPopup(didAccept) {
      if (!didAccept) {
        this.auth.logout();
      }
      this.hideCGUPopup();
      this.showPasswordPopup();
    },
    onClosedPasswordPopup() {
      this.hidePasswordPopup();
    },
    resolveCGUPopupState() {
      this.toBlur = !this.getDidReadCGU;
      this.displayPopup = !this.getDidReadCGU;
    },
    hideCGUPopup() {
      this.displayPopup = false;
      this.toBlur = false;
    },
    hidePasswordPopup() {
      this.displayPasswordPopup = false;
      this.toBlur = false;
    },
    showCGUPopup() {
      this.toBlur = true;
      this.displayPopup = true;
    },
    showPasswordPopup() {
      this.toBlur = true;
      this.displayPasswordPopup = true;
    },
    /**
     * Check if all events progress are done
     * @return bool
     */
    checkEventProgress() {
      for (let eventName in this.eventsProgress) {
        if (this.eventsProgress[eventName] === false) {
          return false;
        }
      }
      return true;
    },
    /**
     *
     * @param {string} eventName
     */
    onEventProgress(eventName) {
      this.eventsProgress[eventName] = true;

      if (this.checkEventProgress()) {
        emitter.emit(events.loading.hide);
      }
    },
  },
  created() {
    for (let eventName in this.eventsProgress) {
      emitter.on(events.home[eventName], () => {
        this.onEventProgress(eventName);
      });
    }
    this.setClientLastLogin();
  },

  mounted() {
    emitter.emit(events.loading.show);
    this.resolveCGUPopupState();
    getCurrentCGURequest()
      .then((data) => {
        // check if we get a cgu
        if (data.cgu && data.cgu.id) {
          if (
            data.cgu.clientLastTerm &&
            data.cgu.id !== data.cgu.clientLastTerm.id
          ) {
            // display cgu
            this.idTerm = data.cgu.id;
            this.setDidReadCGU(false);
            this.showCGUPopup();
          }
        }
      })
      .catch((reason) => {
        console.error(reason);
      })
      .finally(() => {
        emitter.emit(events.home.cguDone);
      });
    getMainDossierAndTimelineStatusRequest().then((data) => {
      if (data?.dossier?.rdv) {
        this.statusState = data?.dossier?.rdv[0]?.status;
      }
      // Pour tester un statut différent, définir la valeur sur data.dossier.status
    });
  },
};
</script>
